<template>
  <div>
    <cta-banner :title="$route.name"/>    
    <section class="container mx-auto pt-20">
      <div class="w-full pb-12">
        <h2 class="text-3xl font-bold mb-7 title relative">{{ header.titulo }}</h2>
        <p v-html="header.cuerpo" style="white-space: pre-wrap" align="justify"></p><br>
      </div>
    </section>
    
  </div>
</template>

<script>
import API from "@/api";
import CtaBanner from '../../components/common/CtaBanner.vue';
import ArrowCollapseDown from 'vue-material-design-icons/ArrowCollapseDown.vue';
import FileDocumentOutline from 'vue-material-design-icons/FileDocumentOutline.vue';
import {mapState} from "vuex";

export default {
  name: "InformacionLocal",
  components: {
    CtaBanner,
    ArrowCollapseDown,
    FileDocumentOutline
  },
  data() {
    return {
      header: {},
    };
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  async created() {
    this.getInfo();
  },
  methods: { 
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.header = (items[i].codigo === 'L111') ? items[i] : this.header;
      }
    },
  }
}
</script>

<style>
  .color_a {
      color: #0d6efd;
  }
</style>
